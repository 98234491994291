@import '../../scss/variables'

.DevelopmentServices
    .landingSection
        h1
            letter-spacing: 10px
        .CompanyLogo
            height: 100px

    .teachableSection
        .videoLinkContainer
            a:hover
                color: rgba(3, 37, 41, 1) !important
            .playIcon
                transform: translate(-50%, -50%)

    .insightBox
        border: 2px solid $gunMetalGrey
