@import '../../scss/variables'

.Directory
    .landingSection
        .unitAlert
            margin-top: -16px
        h1
            letter-spacing: 10px
    .leaflet-container
        z-index: 1
        height: 60vh
        div.leaflet-popup
            text-align: left
            .leaflet-popup-content-wrapper
                color: $gunMetalGrey
                border-radius: 4px
                border-top: 22px solid $contractorsGarageOrange
                .leaflet-popup-content
                    display: flex
                    flex-direction: column
                    width: 300px !important
            
            .leaflet-popup-close-button
                color: $gunMetalGrey
   
    .listingSection
        overflow: hidden
        .checkUsOutContainer
            z-index: 2
        .iconContainer
            display: table
            width: 2000px
            top: -36px
            left: 0px
            z-index: 1
            svg
                color: $contractorsGarageOrangeLight1
                width: 50px
                height: 50px
                flex-basis: 25%
                margin: 20px 80px


        
@media (min-width: 768px)
    .Directory
        .leaflet-container
            height: 800px