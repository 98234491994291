@import '../../scss/variables'
    
.AboutUs
    .landingSection
        h1
            letter-spacing: 10px
        .CompanyLogo
            height: 100px

    .locationImageSection
        height: 600px
        background: url('./images/169hwyOlathe.jpeg') center no-repeat
        background-size: cover

    .parallaxSection
        height: 600px
        background-size: cover
        background: url('./images/rearloading.jpeg')
        background-attachment: fixed
        background-position-x: 25%

    .descriptionSection
        color: whitesmoke
        .CompanyLogo
            height: 100px


    .locationsSection
        overflow: hidden
        .checkUsOutContainer
            z-index: 2
        .iconContainer
            display: table
            width: 2000px
            top: -36px
            left: 0px
            z-index: 1
            svg
                color: $contractorsGarageOrangeLight1
                width: 50px
                height: 50px
                flex-basis: 25%
                margin: 20px 80px
        .pinsImageContainer
            z-index: 2
            width: 300px
            height: 220px
            top: 0px
            left: calc(50% + 160px)