@import '../../scss/variables'

.Seminar
    .landingSection
        .CompanyLogo
            height: 100px
    .overviewSection
        color: whitesmoke
    .scheduleSection
        .leaflet-container
            z-index: 1
            height: 400px
    .speakerSection
        .profilePic
            width: 200px
    .signUpSection
        color: whitesmoke
        .usernameField
            position: absolute
            opacity: 0
            top: 0
            left: 0
            height: 0
            width: 0
            z-index: -1