@import '../../scss/variables'
    
.Home
    .Carousel
        height: calc(60vh - #{$headerHeight})
        > div > div > ul
            cursor: default

        img
            transition: transform 5s linear
            transform: scale(1)

        li[aria-live]
            img
                transform: scale(1.2)
                
    .aboutSection
        color: $darkTextColor

        .textSection
            max-width: 500px

            .homepageIcon
                width: 40px
                height: 40px

            .checkIcon
                color: $contractorsGarageOrange

        .collageImage
            width: 100%
            box-shadow: 0px 0px 20px 0px $gunMetalGrey

    .locationsSection
        overflow: hidden
        .checkUsOutContainer
            z-index: 2
        .iconContainer
            display: table
            width: 2000px
            top: -36px
            left: 0px
            z-index: 1
            svg
                color: $contractorsGarageOrangeLight1
                width: 50px
                height: 50px
                flex-basis: 25%
                margin: 20px 80px
        .pinsImageContainer
            z-index: 2
            width: 300px
            height: 220px
            top: 0px
            left: calc(50% + 160px)

@media (min-width: 768px)
    .Home
        .Carousel
            height: calc(100vh - #{$headerHeight})
            
    .CarouselOverlay
        background-color: transparentize($gunMetalGrey, .1)
        top: 75%
        left: 10%
        width: 600px
        height: 160px
        color: whitesmoke

        .divider
            width: 40px
            height: 6px
            background-color: $contractorsGarageOrange