@import '../../scss/variables'

.Header
    position: fixed
    top: 0px
    left: 0px
    right: 0px
    background-color: $headerColor
    border-bottom: $headerBorderWidth solid $gunMetalGrey
    z-index: 10

    .backgroundContainer
        height: $headerHeight
        top: 0px
        left: 0px
        right: 0px

        .darkSection
            position: relative
            &::before
                content: ''
                display: inline-block
                position: absolute
                left: -62px
                width: 62px
                height: $headerHeight
                background-color: $gunMetalGrey

            &::after
                content: ''
                display: inline-block
                position: absolute
                left: -97px
                top: -22px
                width: 88px
                height: $headerHeight
                background-color: white
                transform: rotate(-45deg)

    .navbar-collapse
        flex-grow: unset
    
    .navbar-light
        .navbar-brand
            .CompanyLogo
                height: $headerInnerHeight

@media (min-width: 768px)
    .Header
        .navbar-light .navbar-nav
            .nav-link
                height: calc(#{$headerHeight} + #{$headerBorderWidth})
                line-height: 48px
                margin-top: -8px
                margin-bottom: -10px
                color: $headerTextColor
                &.active
                    border-bottom: 6px solid $headerTextColorHover
                    color: $headerTextColorHover
                &:hover, &:focus
                    color: $headerTextColorHover