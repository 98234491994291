@import '../../scss/variables'

.LocationDetail
    .bannerImage
        position: relative
        width: 100%
        overflow: hidden
        height: 30vh
        img
            width: 100%
    .loadingSection
        height: 50vh

    .landingSection
        h1
            letter-spacing: 10px
        .leaflet-container
            z-index: 1
            height: 400px
    
    .videoSection
        .seeMorePanel
            z-index: 1
            bottom: -3rem
            background-color: $backgroundColor
            box-shadow: 0 0 30px 40px whitesmoke
        .noPointerEvents
            pointer-events: none

    .featuresSection
        color: whitesmoke

    .unitInfoSection
        a.nav-link
            color: $gunMetalGrey
            font-weight: bold
            cursor: pointer
        .availableUnitsTableWrapper,
        .unitSummaryTableWrapper
            background-color: $backgroundColor
        .availableUnitsTable,
        .unitSummaryTable
            min-width: 600px

            
    .gallerySection
        .Carousel
            height: 40vh
            figcaption
                display: none

    .paymentPopup
        cursor: pointer
        background-color: $backgroundColor
        width: 90%
        left: 5%
        bottom: 0px
        border: 2px solid $contractorsGarageOrange
        border-bottom: 0px
        border-radius: 8px 8px 0px 0px
        transition: transform .3s ease
        z-index: 1

        &.collapsed
            transform: translateY(70px)

        &.expanded
            transform: translateY(0px)
            .chevronIcon
                transform: rotateX(180deg)

        .chevronIcon
            right: 16px
            top: 8px
            transition: transform .6s ease

// Bootstrap modal code override
.paymentModal
    .modal-header .close
        padding: 1rem 1rem
        margin: -1rem -1rem -1rem auto
    button.close
        background-color: transparent
        border: 0
    .close
        float: right
        font-size: 1.5rem
        font-weight: 700
        line-height: 1
        color: black
        text-shadow: 0 1px 0 white
        opacity: .5
        &:hover
            opacity: .75


@media (min-width: 768px)
    .LocationDetail
        .unitInfoSection
            .availableUnitsTableWrapper,
            .unitSummaryTableWrapper
                background-color: initial

        .gallerySection
            .Carousel
                height: 80vh
                figcaption
                    display: block
                    span
                        padding: 0

        .paymentPopup
            background-color: $backgroundColor
            width: 300px
            right: 5%
            left: unset
            