@import './scss/variables'

html 
    scroll-behavior: auto !important
    body
        background-color: $backgroundColor
        padding-top: $headerHeight

    .themeText
        color: $contractorsGarageOrange

    .themeBackground
        background-color: $contractorsGarageOrange

    .gunMetalText
        color: $gunMetalGrey

    .gunMetalBackground
        background-color: $gunMetalGrey