// Theme Colors
$contractorsGarageOrange: #CC6633
$contractorsGarageOrangeLight1: lighten($contractorsGarageOrange, 10)
$contractorsGarageOrangeLight2: lighten($contractorsGarageOrange, 20)
$contractorsGarageOrangeDark1: darken($contractorsGarageOrange, 10)
$contractorsGarageOrangeDark2: darken($contractorsGarageOrange, 20)
$gunMetalGrey: #3B3D40
$backgroundColor: whitesmoke

// Header Colors
$headerColor: white
$headerTextColor: whitesmoke
$headerTextColorHover: $contractorsGarageOrange

// Font Colors
$darkTextColor: $gunMetalGrey